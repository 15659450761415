import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { clsUtils, validadoresFrontEnd } from 'zlib-utils';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { StatusFormType } from '@/utils/dataTypes';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
import QuizPergunta from './QuizPergunta.vue';
import QuizResultado from './QuizResultado.vue';
import QuizRelatorio from './QuizRelatorio.vue';
var QuizCls = /** @class */ (function (_super) {
    __extends(QuizCls, _super);
    function QuizCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.indiceTab = 0;
        _this.pesquisa = '';
        _this.formValid = false;
        _this.editedItem = _this.resetModel();
        _this.tmpRegistro = _this.resetModel();
        _this.status = StatusFormType.inicial;
        _this.clsApollo = new ClsApolloClient();
        _this.cabecalhos = [
            { text: 'Descrição', value: 'descricao' },
            { text: 'Ativo', value: 'ativo' },
            { text: 'Ícone', value: 'icone' },
            { text: 'Ações', value: 'actions', sortable: false }
        ];
        _this.registros = [];
        return _this;
    }
    Object.defineProperty(QuizCls.prototype, "validadoresFrontEnd", {
        get: function () { return validadoresFrontEnd; },
        enumerable: false,
        configurable: true
    });
    QuizCls.prototype.resetModel = function () {
        return {
            ativo: false,
            descricao: '',
            idQuiz: 0,
            icone: '',
            msgConfirmarEmail: '',
            prefixoResultado: ''
        };
    };
    Object.defineProperty(QuizCls.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuizCls.prototype, "tituloDialog", {
        get: function () {
            return this.status == StatusFormType.editando ? "Editar" : this.status == StatusFormType.visualizando ? "Exibir" : "Excluir";
        },
        enumerable: false,
        configurable: true
    });
    QuizCls.prototype.mounted = function () {
        this.getDetalhe();
    };
    QuizCls.prototype.btCancelar = function () {
        this.status = StatusFormType.inicial;
    };
    QuizCls.prototype.btIncluir = function () {
        this.status = StatusFormType.incluindo;
        this.editedItem.idQuiz = 0;
        if (EMDESENVOLVIMENTO) {
            this.editedItem.ativo = true;
            this.editedItem.descricao = 'Teste de Descrição';
        }
        else {
            this.editedItem = this.resetModel();
        }
    };
    QuizCls.prototype.btConfirmar = function () {
        var _this = this;
        if (this.status == StatusFormType.editando || this.status == StatusFormType.incluindo) {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                var mutation = "\n          updateQuiz(dados: " + clsUtils.ConverterEmGql(this.editedItem) + " ) {\n            ok\n            mensagem\n          }\n        ";
                var mensagem = (this.status == StatusFormType.incluindo ? 'Incluindo' : 'Alterando').concat(' Quiz');
                this.clsApollo.mutation(this, mutation, 'updateQuiz', mensagem).then(function (rs) {
                    if (rs.ok) {
                        _this.status = StatusFormType.inicial;
                        _this.editedItem = _this.resetModel();
                        _this.tmpRegistro = _this.resetModel();
                        _this.getDetalhe();
                    }
                });
            }
        }
        else if (this.status == StatusFormType.excluindo) {
            var mutation = "\n        delQuiz(idQuiz: " + this.editedItem.idQuiz + " ) {\n          ok\n          mensagem\n        }\n      ";
            this.clsApollo.mutation(this, mutation, 'delQuiz', 'Excluindo Quiz....').then(function (rs) {
                if (rs.ok) {
                    _this.status = StatusFormType.inicial;
                    _this.editedItem = _this.resetModel();
                    _this.tmpRegistro = _this.resetModel();
                    _this.getDetalhe();
                }
            });
        }
    };
    QuizCls.prototype.deleteItem = function (item) {
        this.editedItem = Object.assign({}, item);
        this.status = StatusFormType.excluindo;
    };
    QuizCls.prototype.editItem = function (item) {
        this.editedItem = Object.assign({}, item);
        this.tmpRegistro = Object.assign({}, item);
        this.status = StatusFormType.editando;
    };
    QuizCls.prototype.getDetalhe = function () {
        var _this = this;
        var query = "\n      getQuiz(pesquisa: \"" + this.pesquisa + "\") {\n        idQuiz\n        descricao\n        icone\n        ativo\n        msgConfirmarEmail\n        prefixoResultado\n      }\n    ";
        this.clsApollo.query(this, query, 'getQuiz', 'Pequisando Quiz...').then(function (rs) {
            _this.registros = rs;
        }).catch(function (err) {
            console.log('erro: ', err);
        });
    };
    QuizCls.prototype.btPesquisar = function () {
        this.getDetalhe();
    };
    QuizCls = __decorate([
        Component({
            components: {
                QuizPergunta: QuizPergunta,
                QuizRelatorio: QuizRelatorio,
                QuizResultado: QuizResultado
            }
        })
    ], QuizCls);
    return QuizCls;
}(Vue));
export default QuizCls;
