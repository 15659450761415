import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib'; //Globally import VTextField
import { createProvider } from './vue-apollo';
/*
// FIREBASE / FCM ================== INICIO

import firebase from "firebase/app"

import "firebase/analytics"
import "firebase/messaging"
import "firebase/performance"

var firebaseConfig = {
  apiKey: "AIzaSyCj10bJINWUPveWgkiBUAgNUC3A8jt8_ZM",
  authDomain: "quiz-fleek.firebaseapp.com",
  projectId: "quiz-fleek",
  storageBucket: "quiz-fleek.appspot.com",
  messagingSenderId: "445567973949",
  appId: "1:445567973949:web:e04fbe446156e24c69c854",
  measurementId: "G-RB5397YG9H"
}
// Initialize Firebase
firebase.initializeApp( firebaseConfig )
firebase.analytics()

const messaging = firebase.messaging()

messaging.getToken( { vapidKey: "BCVcpU8pA5dJiBNbSCYJ9ueeavdDsyeSO4hOEMIZ7JeLvu6_C9V5zTAKX4NRKTwarXyexQdL3i5mFVKQ2wW63C0" } ).then( token => {
  console.log( token )
} )

messaging.onMessage( ( payload ) => {
  console.log( 'Mensagem Recebida: ', payload );
  // ...
} )

// FIREBASE / FCM ================== TERMINO

*/
Vue.component('v-text-field', VTextField);
Vue.use(VCurrencyField, {
    locale: 'pt-BR',
    decimalLength: 2,
    autoDecimalMode: true,
    min: null,
    max: null,
    defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true
});
// Vue.component( 'VCurrencyField', VCurrencyField )
Vue.config.productionTip = false;
new Vue({
    router: router,
    store: store,
    vuetify: vuetify,
    apolloProvider: createProvider(),
    render: function (h) { return h(App); }
}).$mount('#app');
