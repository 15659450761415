export var StatusFormType;
(function (StatusFormType) {
    StatusFormType[StatusFormType["inicial"] = 0] = "inicial";
    StatusFormType[StatusFormType["valido"] = 1] = "valido";
    StatusFormType[StatusFormType["erro"] = 2] = "erro";
    StatusFormType[StatusFormType["processando"] = 3] = "processando";
    StatusFormType[StatusFormType["editando"] = 4] = "editando";
    StatusFormType[StatusFormType["incluindo"] = 5] = "incluindo";
    StatusFormType[StatusFormType["excluindo"] = 6] = "excluindo";
    StatusFormType[StatusFormType["visualizando"] = 7] = "visualizando";
})(StatusFormType || (StatusFormType = {}));
/*
import { QuizTotalizadorInterface } from '@/interfaces/backend/QuizInterfaces'
import { ControleStoreModule } from '@/store/modules/ControleStore'
import _ from 'lodash'


interface getRolesInterface {
  totalizador ( totalizador: string ): QuizTotalizadorInterface
}

export const getRoles: any = {

  totalizador: ( totalizador: string ): QuizTotalizadorInterface | undefined => {
    return _.find( ControleStoreModule.quizTotalizador, { totalizador: totalizador } )
  }

}

*/ 
