import Vue from 'vue';
import VueRouter from 'vue-router';
import MenuInicial from '../layout/MenuInicial.vue';
import Questionario from '../views/Questionario.vue';
import Login from '../views/login/Login.vue';
import Inicio from '../views/Inicio.vue';
import Termino from '../views/Termino.vue';
import Quiz from '../views/admin/Quiz.vue';
import Teste from '../testes_apagar/Teste.vue';
import { LoginStoreModule } from '@/store/modules/LoginStore';
import { ControleStoreModule } from '@/store/modules/ControleStore';
Vue.use(VueRouter);
var rotasAdministrativas = [{
        path: '/Quiz',
        name: 'Quiz',
        component: Quiz
    }];
var rotasObrigatorioIniciarQuestinario = [
    {
        path: '/Questionario',
        name: 'Questionario',
        component: Questionario
    },
    {
        path: '/Termino',
        name: 'Termino',
        component: Termino,
    }
];
var rotasGerais = [
    {
        path: '/Inicio/:idQuiz',
        name: 'Inicio',
        component: Inicio,
        props: true
    },
    {
        path: '/Login',
        name: 'Login',
        component: Login,
        props: true
    },
    {
        path: '/',
        name: 'MenuInicial',
        component: MenuInicial
    },
    {
        path: '/Teste',
        name: 'Teste',
        component: Teste,
    }
];
var router = new VueRouter({
    routes: rotasGerais.concat(rotasAdministrativas).concat(rotasObrigatorioIniciarQuestinario)
});
router.beforeResolve(function (to, from, next) {
    if (rotasGerais.find(function (e) { return e.name == to.name; })
        || (rotasAdministrativas.find(function (e) { return e.name == to.name; }) && LoginStoreModule.logado)
        || (rotasObrigatorioIniciarQuestinario.find(function (e) { return e.name == to.name; }) && (ControleStoreModule.iniciado || ControleStoreModule.finalizado))) {
        next();
    }
    else {
        if (rotasAdministrativas.find(function (e) { return e.name == to.name; })) {
            next({ replace: true, path: '/Login' });
        }
        else {
            next({ replace: true, path: '/' });
        }
    }
});
export default router;
