export var TipoPerguntaRole;
(function (TipoPerguntaRole) {
    TipoPerguntaRole[TipoPerguntaRole["SELECAO"] = 10] = "SELECAO";
    TipoPerguntaRole[TipoPerguntaRole["TEXTO"] = 20] = "TEXTO";
})(TipoPerguntaRole || (TipoPerguntaRole = {}));
export var TipoUsuarioRole;
(function (TipoUsuarioRole) {
    TipoUsuarioRole[TipoUsuarioRole["ADMIN"] = 10] = "ADMIN";
    TipoUsuarioRole[TipoUsuarioRole["USUARIO"] = 20] = "USUARIO";
})(TipoUsuarioRole || (TipoUsuarioRole = {}));
/*
// Revisado em Utilização...
export const StatusTiposUsuariosPermitidoAlterarStatusNovoCliente = [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR, TipoUsuarioRole.VENDEDOR]
export const StatusPermitidoVendedorNovosClientes = [StatusRole.TRATATIVA, StatusRole.OPORTUNIDADE, StatusRole.NEGOCIACAO, StatusRole.NAO_ATENDE, StatusRole.INCORRETO, StatusRole.SEM_INTERESSE, StatusRole.CONTATO_FUTURO, StatusRole.PRE_MATRICULA]

// A Revisar
export const StatusTravadoParaSupervisor: Array<number> = [StatusRole.NEGOCIACAO, StatusRole.OPORTUNIDADE, StatusRole.PRE_MATRICULA]
export const StatusFecharLeadRole: Array<number> = [StatusRole.NAO_ATENDE, StatusRole.INCORRETO, StatusRole.SEM_INTERESSE, StatusRole.CONTATO_FUTURO, StatusRole.MATRICULA]

export const StatusRoleDescricao: Array<StatusInterface> = [
  { idStatus: StatusRole.NOVO, descricao: 'Novo' },
  { idStatus: StatusRole.TRATATIVA, descricao: 'Tratativa' },
  { idStatus: StatusRole.OPORTUNIDADE, descricao: 'Oportunidade' },
  { idStatus: StatusRole.NEGOCIACAO, descricao: 'Negociação' },
  { idStatus: StatusRole.NAO_ATENDE, descricao: 'Não Atende' },
  { idStatus: StatusRole.INCORRETO, descricao: 'Incorreto' },
  { idStatus: StatusRole.SEM_INTERESSE, descricao: 'Sem Interesse' },
  { idStatus: StatusRole.CONTATO_FUTURO, descricao: 'Contato Futuro' },
  { idStatus: StatusRole.PRE_MATRICULA, descricao: 'Pré Matrícula' },
  { idStatus: StatusRole.MATRICULA, descricao: 'Matrícula' }
]

export enum CanalRole {
  SITE = 1,
  EMAIL = 2,
  WHATSAPP = 3,
  VISITA = 4,
  TELEFONE = 5,
  INSTAGRAM = 6,
  FACEBOOK = 7
}


export const CanalRoleDescricao: Array<CanalInterface> = [
  { idCanal: CanalRole.SITE, descricao: 'Site' },
  { idCanal: CanalRole.EMAIL, descricao: 'Email' },
  { idCanal: CanalRole.WHATSAPP, descricao: 'Whatsapp' },
  { idCanal: CanalRole.VISITA, descricao: 'Visita' },
  { idCanal: CanalRole.TELEFONE, descricao: 'Telefone' },
  { idCanal: CanalRole.INSTAGRAM, descricao: 'Instagram' },
  { idCanal: CanalRole.FACEBOOK, descricao: 'Facebook' }
]

*/
export var TipoUsuarioRoleDescricao = [
    { idTipo: TipoUsuarioRole.USUARIO, descricao: 'Usuário' },
    { idTipo: TipoUsuarioRole.ADMIN, descricao: 'Administrador' }
];
