import { __decorate, __extends } from "tslib";
import { ControleStoreModule } from '@/store/modules/ControleStore';
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';
import { clsUtils } from 'zlib-utils';
var InicioCls = /** @class */ (function (_super) {
    __extends(InicioCls, _super);
    function InicioCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.clsApollo = new ClsApolloClient();
        _this.dados = {
            idQuiz: 0,
            nome: '',
            email: '',
            whatsApp: ''
        };
        _this.nomeDoQuiz = '';
        _this.msgConfirmarEmail = '';
        _this.perguntas = [];
        _this.resultados = [];
        return _this;
    }
    // private clsApolloClient: ClsApolloClient = new ClsApolloClient()
    InicioCls.prototype.mounted = function () {
        if (this.idQuiz && parseInt(this.idQuiz) != 0) {
            this.loadQuestionario();
        }
        // let tmpToken: string = localStorage.getItem( AUTH_TOKEN ) || ''
    };
    Object.defineProperty(InicioCls.prototype, "ControleStore", {
        get: function () {
            return ControleStoreModule;
        },
        enumerable: false,
        configurable: true
    });
    InicioCls.prototype.loadQuestionario = function () {
        var _this = this;
        var getQuizById = "\n      getQuizById (idQuiz: " + this.idQuiz + ")\n      {\n        idQuiz\n        descricao\n        icone\n        msgConfirmarEmail\n        resultados {\n          chaveTotalizador\n          idQuizResultado\n        }\n        perguntas {\n          idQuizPergunta\n          idQuiz\n          textoAntesImagem\n          textoAposImagem\n          imagemPath\n          imagemLargura\n          imagemAltura\n          opcoes {\n            opcao\n            imagemPath\n            imagemLargura\n            imagemAltura\n            chaveTotalizador\n          }\n          respostaTipoTexto\n          respostaTipoTextoTamanho\n          respostaTipoTextoMascara\n          respostaTipoTextoTipo\n          respostaTipoTextoTotalizadorCorreto\n          respostaTipoTextoTotalizadorErrado\n          correcao\n          respostaTipoTextoCorreta\n          ativa\n          ordem\n        }\n      }\n    ";
        this.clsApollo.query(this, getQuizById, 'getQuizById', 'Carregando Quiz...').then(function (rs) {
            if (rs && rs.perguntas && rs.perguntas.length > 0) {
                _this.perguntas = rs.perguntas.filter(function (v) { return v.ativa; });
                _this.nomeDoQuiz = rs.descricao;
                _this.resultados = rs.resultados;
                _this.msgConfirmarEmail = rs.msgConfirmarEmail;
            }
            else {
                MensagemStoreModule.exibirMensagem({ mensagem: 'Erro ao Carregar Quiz... ', titulo: 'Erro!!!', tipo: TipoMensagemType.erro });
            }
        }).catch(function () {
            MensagemStoreModule.exibirMensagem({ mensagem: 'Erro no servidor... ', titulo: 'Erro Login', tipo: TipoMensagemType.erro });
        });
    };
    InicioCls.prototype.btProximo = function () {
        var _this = this;
        this.updateCliente().then(function (rs) {
            if (rs > 0) {
                ControleStoreModule.iniciarControle({ idQuiz: 1, idClienteQuiz: rs, perguntas: _this.perguntas, resultados: _this.resultados, dadosCliente: { email: _this.dados.email, nome: _this.dados.nome, msgConfirmarEmail: _this.msgConfirmarEmail } });
                _this.$router.push("/Questionario");
            }
        });
    };
    // Atualiza Cliente e retorno idClienteQuiz
    InicioCls.prototype.updateCliente = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.dados.idQuiz = parseInt(_this.idQuiz);
            var mutation = "\n        updateCliente (dados: " + clsUtils.ConverterEmGql(_this.dados) + ")\n      ";
            _this.clsApollo.mutation(_this, mutation, 'updateCliente', 'Atualizando Dados...').then(function (rs) {
                if (rs && rs > 0) {
                    resolve(rs);
                }
                else {
                    MensagemStoreModule.exibirMensagem({ mensagem: 'Erro ao Atualizar Dados... ', titulo: 'Tente Novamente!!!', tipo: TipoMensagemType.erro });
                    resolve(0);
                }
            }).catch(function () {
                MensagemStoreModule.exibirMensagem({ mensagem: 'Erro no servidor... ', titulo: 'Tente Mais Tarde!', tipo: TipoMensagemType.erro });
                resolve(0);
            });
        });
    };
    __decorate([
        Prop()
    ], InicioCls.prototype, "idQuiz", void 0);
    InicioCls = __decorate([
        Component({
            directives: {
                mask: mask
            }
        })
    ], InicioCls);
    return InicioCls;
}(Vue));
export default InicioCls;
