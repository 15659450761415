// export const PAGINA_INICIAL_EMDESENVOLVIMENTO = '/Teste'
export var PAGINA_INICIAL_EMDESENVOLVIMENTO = '/Quiz';
export var PAGINA_INICIAL_PADRAO = '/Quiz';
export var MENU = [
/*
{
  icone: 'mdi-chevron-down',
  iconeAlt: 'mdi-chevron-up',
  texto: 'Meu Curriculum',
  expandido: true,
  permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.USUARIO, TipoUsuarioRole.ALUNO],
  filhos: [
    { icone: 'mdi-account-details', texto: 'Curriculum', link: '/StepByStep', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.USUARIO, TipoUsuarioRole.ALUNO], },
    { icone: 'mdi-account-circle', texto: 'Selfie', link: '/Selfie', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.USUARIO, TipoUsuarioRole.ALUNO], },
    { icone: 'mdi-download', texto: 'Download', link: '/Download', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.USUARIO, TipoUsuarioRole.ALUNO], }
  ],
},
{
  icone: 'mdi-chevron-down',
  iconeAlt: 'mdi-chevron-up',
  texto: 'RH',
  expandido: true,
  permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.RH],
  filhos: [
    { icone: 'mdi-account-details', texto: 'Empresas', link: '/Empresas', corBotao: 'white', corIcone: 'orange darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.RH], },
    { icone: 'mdi-account-circle', texto: 'Vagas RH', link: '/VagasRH', corBotao: 'white', corIcone: 'orange darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.RH], }
  ],
},
*/
/*
  {
    icone: 'mdi-chevron-down',
    iconeAlt: 'mdi-chevron-up',
    texto: 'Adultos',
    expandido: true,
    filhos: [
       { icone: 'mdi-post-outline', texto: 'Adulto - Nível 01', link: '/Inicio/1', corBotao: 'white', corIcone: 'blue darken-3' }
    ],
  },
  */
// { icone: 'mdi-logout', texto: 'Sair', link: '/Logout', corBotao: 'white', corIcone: 'blue darken-3' }
];
