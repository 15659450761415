import { __decorate, __extends } from "tslib";
import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators';
import store from '..';
var ControleStore = /** @class */ (function (_super) {
    __extends(ControleStore, _super);
    function ControleStore() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dadosCliente = { nome: '', email: '', msgConfirmarEmail: '' };
        _this.perguntaAtual = 0;
        _this.idQuiz = 0;
        _this.idClienteQuiz = 0;
        _this.finalizado = false;
        _this.iniciado = false;
        _this.perguntas = [];
        _this.respostas = [];
        _this.resumo = [];
        _this.resultado = [];
        _this.respostaAtual = '';
        return _this;
    }
    Object.defineProperty(ControleStore.prototype, "percentualConcluido", {
        get: function () {
            return this.perguntaAtual / this.perguntas.length * 100;
        },
        enumerable: false,
        configurable: true
    });
    ControleStore.prototype.proximaPergunta = function () {
        var _this = this;
        if (this.respostaAtual.length > 0) {
            // Incrementa o contador de resposta
            var perguntaAtual = this.perguntas[this.perguntaAtual];
            // Executa o totalizador das opções
            if (perguntaAtual.opcoes && perguntaAtual.opcoes.length > 0) {
                store.commit('incrementarTotalizador', perguntaAtual.opcoes[perguntaAtual.opcoes.findIndex(function (x) { return x.opcao === _this.respostaAtual; })].chaveTotalizador);
            }
            // Se for tipo texto e tiver os totalizadores e o gabarito, executa o totalizador
            if (perguntaAtual.respostaTipoTextoTamanho
                && perguntaAtual.respostaTipoTextoCorreta
                && perguntaAtual.respostaTipoTextoCorreta.length > 0
                && perguntaAtual.respostaTipoTextoTotalizadorCorreto
                && perguntaAtual.respostaTipoTextoTotalizadorCorreto.length > 0
                && perguntaAtual.respostaTipoTextoTotalizadorErrado
                && perguntaAtual.respostaTipoTextoTotalizadorErrado.length > 0) {
                store.commit('incrementarTotalizador', perguntaAtual.respostaTipoTextoCorreta == this.respostaAtual ? perguntaAtual.respostaTipoTextoTotalizadorCorreto : perguntaAtual.respostaTipoTextoTotalizadorErrado);
            }
            this.respostas.push({ resposta: this.respostaAtual, idQuizPergunta: this.perguntas[this.perguntaAtual].idQuizPergunta, idClienteQuiz: this.idClienteQuiz });
            // Verificar se finalizou o teste
            if (!this.finalizado) {
                this.perguntaAtual++;
                this.respostaAtual = "";
            }
            this.finalizado = this.perguntaAtual == this.perguntas.length;
        }
    };
    ControleStore.prototype.iniciarControle = function (payload) {
        this.perguntaAtual = 0;
        this.finalizado = false;
        this.iniciado = true;
        this.respostas = [];
        this.resultado = payload.resultados.map(function (e) {
            return {
                idClienteQuiz: payload.idClienteQuiz,
                chaveTotalizador: e.chaveTotalizador,
                resultado: 0,
                idQuizResultado: e.idQuizResultado
            };
        });
        this.idQuiz = payload.idQuiz;
        this.idClienteQuiz = payload.idClienteQuiz;
        this.perguntas = payload.perguntas;
        this.dadosCliente = payload.dadosCliente;
    };
    ControleStore.prototype.setResposta = function (resposta) {
        this.respostaAtual = resposta;
    };
    ControleStore.prototype.setResumo = function (resumo) {
        this.resumo = resumo;
    };
    ControleStore.prototype.incrementarTotalizador = function (chave) {
        var indice = this.resultado.findIndex(function (e) { return e.chaveTotalizador == chave; });
        if (indice >= 0) {
            this.resultado[indice].resultado++;
        }
    };
    __decorate([
        Mutation
    ], ControleStore.prototype, "proximaPergunta", null);
    __decorate([
        Mutation
    ], ControleStore.prototype, "iniciarControle", null);
    __decorate([
        Mutation
    ], ControleStore.prototype, "setResposta", null);
    __decorate([
        Mutation
    ], ControleStore.prototype, "setResumo", null);
    __decorate([
        Mutation
    ], ControleStore.prototype, "incrementarTotalizador", null);
    ControleStore = __decorate([
        Module({ dynamic: true, store: store, name: 'controle' })
    ], ControleStore);
    return ControleStore;
}(VuexModule));
export var ControleStoreModule = getModule(ControleStore, store);
