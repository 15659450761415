import { __decorate, __extends, __spreadArrays } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
import { MENU } from '@/config/Menu';
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore';
import ClsApolloClient from '@/utils/ClsApolloClient';
var MenuInicialCls = /** @class */ (function (_super) {
    __extends(MenuInicialCls, _super);
    function MenuInicialCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.clsApollo = new ClsApolloClient();
        _this.quiz = [];
        _this.refresh = 0;
        return _this;
    }
    MenuInicialCls.prototype.mounted = function () {
        this.loadQuiz();
    };
    Object.defineProperty(MenuInicialCls.prototype, "MENU", {
        get: function () {
            return this.quiz.concat(MENU);
        },
        enumerable: false,
        configurable: true
    });
    MenuInicialCls.prototype.getFilhosMenu = function (oque) {
        var _this = this;
        var retorno = [];
        oque.forEach(function (valor, indice) {
            if (valor.filhos) {
                retorno = __spreadArrays(retorno, _this.getFilhosMenu(valor.filhos));
            }
            else {
                retorno.push(valor);
            }
        });
        return retorno;
    };
    MenuInicialCls.prototype.btClickMenu = function (destino) {
        if (destino && this.$router.currentRoute.path != destino) {
            this.$router.push(destino);
        }
    };
    MenuInicialCls.prototype.loadQuiz = function () {
        var _this = this;
        var getQuiz = "\n      getQuiz (ativo: true)\n      {\n        idQuiz\n        descricao\n        icone\n      }\n    ";
        this.quiz = [];
        this.clsApollo.query(this, getQuiz, 'getQuiz', 'Carregando Quiz...').then(function (rs) {
            if (rs && rs.length > 0) {
                rs.forEach(function (valor) {
                    _this.quiz.push({ icone: valor.icone, texto: valor.descricao, link: '/Inicio/'.concat(valor.idQuiz.toString()), corBotao: 'white', corIcone: 'blue darken-3' });
                });
                _this.refresh++;
            }
            else {
                MensagemStoreModule.exibirMensagem({ mensagem: 'Não há Quiz Cadastrado!', titulo: 'Atenção!!!', tipo: TipoMensagemType.aviso });
            }
        }).catch(function () {
            MensagemStoreModule.exibirMensagem({ mensagem: 'Erro no servidor...!', titulo: 'Erro Login!!!', tipo: TipoMensagemType.erro });
        });
    };
    MenuInicialCls.prototype.btSair = function () {
        window.location.href = 'https://www.fleekcursos.com.br';
    };
    MenuInicialCls = __decorate([
        Component
    ], MenuInicialCls);
    return MenuInicialCls;
}(Vue));
export default MenuInicialCls;
