import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { clsUtils } from 'zlib-utils';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { StatusFormType } from '@/utils/dataTypes';
import { VueEditor } from "vue2-editor";
import { CUSTOMTOOLBAR } from '@/config/CustomToolBar';
var QuizRelatorioCls = /** @class */ (function (_super) {
    __extends(QuizRelatorioCls, _super);
    function QuizRelatorioCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.CUSTOMTOOLBAR = CUSTOMTOOLBAR;
        _this.pesquisa = '';
        _this.formValid = false;
        _this.editedItem = _this.resetModel();
        _this.tmpRegistro = _this.resetModel();
        _this.status = StatusFormType.inicial;
        _this.clsApollo = new ClsApolloClient();
        _this.cabecalhos = [
            { text: 'Descrição', value: 'descricao' },
            { text: 'Titulo', value: 'titulo' },
            { text: 'Condição', value: 'condicao' },
            { text: 'Ordem', value: 'ordem' },
            { text: 'Ações', value: 'actions', sortable: false }
        ];
        _this.registros = [];
        return _this;
    }
    QuizRelatorioCls.prototype.resetModel = function () {
        return {
            idQuizRelatorio: 0,
            idQuiz: 0,
            descricao: '',
            condicao: '',
            ordem: 0,
            explicacao: ''
        };
    };
    Object.defineProperty(QuizRelatorioCls.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuizRelatorioCls.prototype, "tituloDialog", {
        get: function () {
            return this.status == StatusFormType.editando ? "Editar" : this.status == StatusFormType.visualizando ? "Exibir" : "Excluir";
        },
        enumerable: false,
        configurable: true
    });
    QuizRelatorioCls.prototype.mounted = function () {
        this.getDetalhe();
    };
    QuizRelatorioCls.prototype.btCancelar = function () {
        this.status = StatusFormType.inicial;
    };
    QuizRelatorioCls.prototype.btIncluir = function () {
        this.status = StatusFormType.editando;
        this.editedItem = this.resetModel();
    };
    QuizRelatorioCls.prototype.btConfirmar = function () {
        var _this = this;
        if (this.status == StatusFormType.editando || this.status == StatusFormType.incluindo) {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                this.editedItem.idQuiz = this.idQuiz;
                var mutation = "\n          updateQuizRelatorios(dados: " + clsUtils.ConverterEmGql(this.editedItem) + " ) {\n            ok\n            mensagem\n          }\n        ";
                var mensagem = (this.status == StatusFormType.incluindo ? 'Incluindo' : 'Alterando').concat(' Relatorio Quiz');
                this.clsApollo.mutation(this, mutation, 'updateQuizRelatorios', mensagem).then(function (rs) {
                    if (rs.ok) {
                        _this.status = StatusFormType.inicial;
                        _this.getDetalhe();
                    }
                });
            }
        }
        else if (this.status == StatusFormType.excluindo) {
            var mutation = "\n        delQuizRelatorio(idQuizRelatorio: " + this.editedItem.idQuizRelatorio + " ) {\n          ok\n          mensagem\n        }\n      ";
            this.clsApollo.mutation(this, mutation, 'delQuizRelatorio', 'Excluindo Relatorio do Quiz....').then(function (rs) {
                if (rs.ok) {
                    _this.status = StatusFormType.inicial;
                    _this.getDetalhe();
                }
            });
        }
    };
    QuizRelatorioCls.prototype.deleteItem = function (item) {
        this.editedItem = Object.assign({}, item);
        this.status = StatusFormType.excluindo;
    };
    QuizRelatorioCls.prototype.editItem = function (item) {
        this.editedItem = Object.assign({}, item);
        this.tmpRegistro = Object.assign({}, item);
        this.status = StatusFormType.editando;
    };
    QuizRelatorioCls.prototype.getDetalhe = function () {
        var _this = this;
        var query = "\n      getQuizRelatorios(idQuiz: " + this.idQuiz + ") {\n        idQuizRelatorio\n        idQuiz\n        descricao\n        condicao\n        ordem\n        explicacao\n      }\n    ";
        this.clsApollo.query(this, query, 'getQuizRelatorios', 'Pequisando Relatorios...').then(function (rs) {
            // console.log( 'retorno perguntas', rs )
            _this.registros = rs;
            _this.status = StatusFormType.inicial;
        }).catch(function (err) {
            console.log('erro: ', err);
        });
    };
    QuizRelatorioCls.prototype.btPesquisar = function () {
        this.getDetalhe();
    };
    __decorate([
        Prop()
    ], QuizRelatorioCls.prototype, "idQuiz", void 0);
    __decorate([
        Watch('idQuiz')
    ], QuizRelatorioCls.prototype, "getDetalhe", null);
    QuizRelatorioCls = __decorate([
        Component({
            components: {
                VueEditor: VueEditor
            }
        })
    ], QuizRelatorioCls);
    return QuizRelatorioCls;
}(Vue));
export default QuizRelatorioCls;
