import _ from 'lodash';
import gql from 'graphql-tag';
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore';
var DEBUG_REQUISICOES = false;
var DEBUG_RESPOSTAS = false;
var ClsApolloClient = /** @class */ (function () {
    function ClsApolloClient() {
    }
    ClsApolloClient.prototype.query = function (self, query, nomeObjRetorno, aguarde) {
        if (aguarde) {
            MensagemStoreModule.exibirMensagem({ mensagem: aguarde, titulo: 'Aguarde', tipo: TipoMensagemType.aguarde });
        }
        return new Promise(function (resolve) {
            if (DEBUG_REQUISICOES) {
                console.log('Query: ');
                console.log(query);
            }
            return self.$apollo.query({
                query: gql("query { " + query + " }"),
                fetchPolicy: "no-cache"
            }).then(function (rs) {
                if (DEBUG_RESPOSTAS) {
                    console.log('RS Query: ');
                    console.log(JSON.stringify(rs));
                }
                if (!rs || rs.errors || rs.error) {
                    MensagemStoreModule.exibirMensagem({ mensagem: 'Erro no Servidor. Consulte Suporte.', titulo: 'ERRO!!!', tipo: TipoMensagemType.erro });
                    console.log('ERRO 01', rs);
                    resolve('Erro...');
                    /*
                    if ( mensagemAlerta ) {
                      mensagemAlerta.tipo = TipoAlertaMensagemType.erro
                      mensagemAlerta.mensagem = 'Erro no Servidor. Consulte Suporte.'
                      mensagemAlerta.exibir = true
                      mensagemAlerta.titulo = 'ERRO!!!'
                      console.log('ERRO: ',rs)
                    } else {
                      reject( rs.error || rs.erros || rs || 'Erro...' )
                    }
                    */
                }
                else {
                    var objeto = _.find(rs, nomeObjRetorno) || {};
                    if (aguarde) {
                        MensagemStoreModule.ocultarMensagem();
                    }
                    resolve(objeto[nomeObjRetorno]);
                }
            }).catch(function (err) {
                MensagemStoreModule.exibirMensagem({ mensagem: 'Erro no Servidor. Consulte Suporte.', titulo: 'ERRO!!!', tipo: TipoMensagemType.erro });
                console.log('ERRO 02', err);
                resolve('Erro...');
                /*
                if ( mensagemAlerta ) {
                  mensagemAlerta.tipo = TipoAlertaMensagemType.erro
                  mensagemAlerta.mensagem = 'Erro no Servidor. Consulte Suporte.'
                  mensagemAlerta.exibir = true
                  mensagemAlerta.titulo = 'ERRO!!!'
                  console.log( 'ERRO: ', err )
                } else {
                  reject( err.message || 'Erro...' )
                }
                */
            });
        });
    };
    ClsApolloClient.prototype.mutation = function (self, mutation, nomeObjRetorno, aguarde) {
        /*
            if ( mensagemAlerta && mensagem ) {
              mensagemAlerta.mensagem = mensagem
              mensagemAlerta.exibir = true
            }
        */
        if (aguarde) {
            MensagemStoreModule.exibirMensagem({ mensagem: aguarde, titulo: 'Aguarde', tipo: TipoMensagemType.aguarde });
        }
        if (DEBUG_REQUISICOES) {
            console.log('Mutation: ');
            console.log(mutation);
        }
        return new Promise(function (resolve, reject) {
            return self.$apollo.mutate({
                mutation: gql("mutation { " + mutation + " }")
            }).then(function (rs) {
                if (DEBUG_RESPOSTAS) {
                    console.log('RS Mutation: ');
                    console.log(JSON.stringify(rs));
                }
                if (!rs || rs.errors || rs.error) {
                    MensagemStoreModule.exibirMensagem({ mensagem: 'Erro no Servidor. Consulte Suporte.', titulo: 'ERRO!!!', tipo: TipoMensagemType.erro });
                    console.log('ERRO 03', rs);
                    resolve('Erro...');
                    /*
                    if ( mensagemAlerta ) {
                      mensagemAlerta.tipo = TipoAlertaMensagemType.erro
                      mensagemAlerta.mensagem = 'Erro no Servidor. Consulte Suporte.'
                      mensagemAlerta.exibir = true
                      mensagemAlerta.titulo = 'ERRO!!!'
                      console.log( 'ERRO: ', rs )
                      resolve( 'Erro...' as any )
                    } else {
                      reject( rs.error || rs.erros || rs || 'Erro...' )
                    }
                    */
                }
                else {
                    /*
                    if ( mensagemAlerta && rs.mensagem && !rs.ok ) {
                      mensagemAlerta.mensagem = rs.mensagem
                      mensagemAlerta.exibir = true
                    } else if ( mensagemAlerta && rs.ok ) {
                      mensagemAlerta.exibir = false
                    }
          */
                    var objeto = _.find(rs, nomeObjRetorno) || {};
                    if (aguarde) {
                        MensagemStoreModule.ocultarMensagem();
                    }
                    // Retornar o nomeObjRetorno
                    resolve(objeto[nomeObjRetorno]);
                }
            }).catch(function (err) {
                MensagemStoreModule.exibirMensagem({ mensagem: 'Erro no Servidor. Consulte Suporte.', titulo: 'ERRO!!!', tipo: TipoMensagemType.erro });
                console.log('ERRO 04', err);
                resolve('Erro...');
                /*
                if ( mensagemAlerta ) {
                  mensagemAlerta.tipo = TipoAlertaMensagemType.erro
                  mensagemAlerta.mensagem = 'Erro no Servidor. Consulte Suporte.'
                  mensagemAlerta.exibir = true
                  mensagemAlerta.titulo = 'ERRO!!!'
                  console.log( 'ERRO: ', err )
                  resolve( 'Erro...' as any )
                } else {
                  reject( err.message || 'Erro...' )
                }
                */
            });
        });
    };
    return ClsApolloClient;
}());
export default ClsApolloClient;
