import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { clsUtils } from 'zlib-utils';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { StatusFormType } from '@/utils/dataTypes';
import QuizPerguntaOpcoes from '@/views/admin/QuizPerguntaOpcoes.vue';
import { mask } from 'vue-the-mask';
var QuizPerguntaCls = /** @class */ (function (_super) {
    __extends(QuizPerguntaCls, _super);
    function QuizPerguntaCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.keyRefresh = 0;
        _this.pesquisa = '';
        _this.formValid = false;
        _this.editedItem = _this.resetModel();
        _this.tmpRegistro = _this.resetModel();
        _this.status = StatusFormType.inicial;
        _this.clsApollo = new ClsApolloClient();
        _this.cabecalhos = [
            { text: 'Texto', value: 'textoAntesImagem' },
            { text: 'Texto', value: 'textoAposImagem' },
            { text: 'Ativa', value: 'ativa' },
            { text: 'Ícone', value: 'icone' },
            { text: 'Ações', value: 'actions', sortable: false }
        ];
        _this.registros = [];
        return _this;
    }
    QuizPerguntaCls.prototype.resetModel = function () {
        return {
            idQuizPergunta: 0,
            idQuiz: 0,
            textoAntesImagem: '',
            textoAposImagem: '',
            imagemPath: '',
            imagemLargura: '',
            imagemAltura: '',
            opcoes: [],
            respostaTipoTexto: false,
            respostaTipoTextoTamanho: 0,
            respostaTipoTextoMascara: '',
            respostaTipoTextoTipo: '',
            correcao: '',
            respostaTipoTextoCorreta: '',
            respostaTipoTextoTotalizadorCorreto: '',
            respostaTipoTextoTotalizadorErrado: '',
            ativa: false,
            ordem: 0,
        };
    };
    Object.defineProperty(QuizPerguntaCls.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuizPerguntaCls.prototype, "tituloDialog", {
        get: function () {
            return this.status == StatusFormType.editando ? "Editar" : this.status == StatusFormType.visualizando ? "Exibir" : "Excluir";
        },
        enumerable: false,
        configurable: true
    });
    QuizPerguntaCls.prototype.mounted = function () {
        this.getDetalhe();
    };
    QuizPerguntaCls.prototype.btCancelar = function () {
        this.status = StatusFormType.inicial;
    };
    QuizPerguntaCls.prototype.btIncluir = function () {
        this.status = StatusFormType.editando;
        this.editedItem = this.resetModel();
    };
    QuizPerguntaCls.prototype.btConfirmar = function () {
        var _this = this;
        if (this.status == StatusFormType.editando || this.status == StatusFormType.incluindo) {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                this.editedItem.idQuiz = this.idQuiz;
                if (!this.editedItem.opcoes)
                    this.editedItem.opcoes = [];
                var mutation = "\n          updateQuizPerguntas(dados: " + clsUtils.ConverterEmGql(this.editedItem) + " ) {\n            ok\n            mensagem\n          }\n        ";
                var mensagem = (this.status == StatusFormType.incluindo ? 'Incluindo' : 'Alterando').concat(' Quiz');
                this.clsApollo.mutation(this, mutation, 'updateQuizPerguntas', mensagem).then(function (rs) {
                    if (rs.ok) {
                        _this.status = StatusFormType.inicial;
                        _this.editedItem = _this.resetModel();
                        _this.tmpRegistro = _this.resetModel();
                        _this.keyRefresh++;
                        _this.getDetalhe();
                    }
                });
            }
        }
        else if (this.status == StatusFormType.excluindo) {
            var mutation = "\n        delQuizPerguntas(idQuizPergunta: " + this.editedItem.idQuizPergunta + " ) {\n          ok\n          mensagem\n        }\n      ";
            this.clsApollo.mutation(this, mutation, 'delQuizPerguntas', 'Excluindo Pergunta do Quiz....').then(function (rs) {
                if (rs.ok) {
                    _this.status = StatusFormType.inicial;
                    _this.editedItem = _this.resetModel();
                    _this.tmpRegistro = _this.resetModel();
                    _this.keyRefresh++;
                    _this.getDetalhe();
                }
            });
        }
    };
    QuizPerguntaCls.prototype.deleteItem = function (item) {
        this.editedItem = Object.assign({}, item);
        this.status = StatusFormType.excluindo;
    };
    QuizPerguntaCls.prototype.editItem = function (item) {
        this.editedItem = Object.assign({}, item);
        this.tmpRegistro = Object.assign({}, item);
        this.status = StatusFormType.editando;
    };
    QuizPerguntaCls.prototype.getDetalhe = function () {
        var _this = this;
        var query = "\n      getQuizPerguntas(idQuiz: " + this.idQuiz + ") {\n        idQuizPergunta\n        idQuiz\n        textoAntesImagem\n        textoAposImagem\n        imagemPath\n        imagemLargura\n        imagemAltura\n  \t\t\topcoes {\n          opcao\n          imagemPath\n          imagemLargura\n          imagemAltura\n          chaveTotalizador\n        }\n        respostaTipoTexto\n        respostaTipoTextoTamanho\n        respostaTipoTextoMascara\n        respostaTipoTextoTipo\n        respostaTipoTextoTotalizadorCorreto\n        respostaTipoTextoTotalizadorErrado\n        correcao\n        respostaTipoTextoCorreta\n        ativa\n        ordem\n      }\n    ";
        this.clsApollo.query(this, query, 'getQuizPerguntas', 'Pequisando Perguntas...').then(function (rs) {
            // console.log( 'retorno perguntas', rs )
            _this.registros = rs;
            _this.status = StatusFormType.inicial;
        }).catch(function (err) {
            console.log('erro: ', err);
        });
    };
    QuizPerguntaCls.prototype.btPesquisar = function () {
        this.getDetalhe();
    };
    QuizPerguntaCls.prototype.updateOpcoes = function (quais) {
        this.editedItem.opcoes = quais;
    };
    __decorate([
        Prop()
    ], QuizPerguntaCls.prototype, "idQuiz", void 0);
    __decorate([
        Watch('idQuiz')
    ], QuizPerguntaCls.prototype, "getDetalhe", null);
    QuizPerguntaCls = __decorate([
        Component({
            components: { QuizPerguntaOpcoes: QuizPerguntaOpcoes },
            directives: { mask: mask }
        })
    ], QuizPerguntaCls);
    return QuizPerguntaCls;
}(Vue));
export default QuizPerguntaCls;
