import { __decorate, __extends } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
import { VERSAO_SISTEMA } from './config/backend/emDesenvolvimento';
import Mensagem from '@/components/mensagens.vue';
import { ControleStoreModule } from './store/modules/ControleStore';
import { MensagemStoreModule, TipoMensagemType } from './store/modules/MensagemStore';
import ClsApolloClient from './utils/ClsApolloClient';
import { clsUtils } from 'zlib-utils';
import { LoginStoreModule } from './store/modules/LoginStore';
var AppCls = /** @class */ (function (_super) {
    __extends(AppCls, _super);
    function AppCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.tmpIsUpdate = false;
        _this.clsApollo = new ClsApolloClient();
        return _this;
    }
    Object.defineProperty(AppCls.prototype, "VERSAO_SISTEMA", {
        // private get EMDESENVOLVIMENTO (): boolean { return EMDESENVOLVIMENTO }
        get: function () { return VERSAO_SISTEMA; },
        enumerable: false,
        configurable: true
    });
    /*
    private mounted (): void {
      if ( EMDESENVOLVIMENTO && PAGINA_INICIAL_EMDESENVOLVIMENTO.length > 0 ) {
        this.$router.push( PAGINA_INICIAL_EMDESENVOLVIMENTO )
      }
    }
    */
    /*
    private get store (): typeof store {
      return store
    }
    */
    AppCls.prototype.mounted = function () {
        var _this = this;
        var versao = "\n      versao\n    ";
        this.clsApollo.query(this, versao, 'versao', 'Verficando...').then(function (rs) {
            if (rs && rs == VERSAO_SISTEMA) {
                _this.tmpIsUpdate = true;
            }
        }).catch(function () {
            MensagemStoreModule.exibirMensagem({ mensagem: 'Erro no servidor...!', titulo: 'Não podemos iniciar!!!', tipo: TipoMensagemType.erro });
        });
    };
    Object.defineProperty(AppCls.prototype, "isUpdate", {
        get: function () {
            return this.tmpIsUpdate;
        },
        enumerable: false,
        configurable: true
    });
    AppCls.prototype.btUpdate = function () {
        window.location.reload();
    };
    Object.defineProperty(AppCls.prototype, "LoginStoreModule", {
        get: function () {
            return LoginStoreModule;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppCls.prototype, "ControleStoreModule", {
        get: function () {
            return ControleStoreModule;
        },
        enumerable: false,
        configurable: true
    });
    // private drawer = false
    // private items: Array<any> = MENU
    /*
      private btClickMenu ( destino: string ): void {
    
        if ( destino && this.$router.currentRoute.path != destino ) {
          this.$router.push( destino )
        }
    
        // this.drawer = false
    
      }
    */
    AppCls.prototype.proximaPergunta = function () {
        ControleStoreModule.proximaPergunta();
        if (ControleStoreModule.finalizado) {
            this.totalizarQuestionario();
            this.$router.push('/Termino');
        }
    };
    /**
     * Envia Informações das Respostas ao BackEnd
     * Recebe os dados e habilita o resumo
     * Dispara Link Término para apresentação
     */
    AppCls.prototype.totalizarQuestionario = function () {
        // MensagemStoreModule.exibirMensagem( { mensagem: 'Totalizando Quiz...', titulo: 'Aguarde', tipo: TipoMensagemType.aguarde } )
        var updateClienteQuizRespostas = "\n      updateClienteQuizRespostas (idQuiz: " + ControleStoreModule.idQuiz + ", respostas: " + clsUtils.ConverterEmGql(ControleStoreModule.respostas) + ", resultados: " + clsUtils.ConverterEmGql(ControleStoreModule.resultado) + ")\n      {\n        descricaoUsuario\n        formatoUsuario\n        percentual\n      }\n    ";
        this.clsApollo.mutation(this, updateClienteQuizRespostas, 'updateClienteQuizRespostas', 'Totalizando Quiz...').then(function (rs) {
            if (rs && rs.length > 0) {
                ControleStoreModule.setResumo(rs);
            }
            else {
                MensagemStoreModule.exibirMensagem({ mensagem: 'Não há Resposta do Quiz!', titulo: 'Atenção!!!', tipo: TipoMensagemType.erro });
            }
        }).catch(function () {
            MensagemStoreModule.exibirMensagem({ mensagem: 'Erro no servidor...!', titulo: 'Erro ao Receber Respostas!!!', tipo: TipoMensagemType.erro });
        });
    };
    AppCls = __decorate([
        Component({
            components: {
                Mensagem: Mensagem
            }
        })
    ], AppCls);
    return AppCls;
}(Vue));
export default AppCls;
