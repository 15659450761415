import { __decorate, __extends } from "tslib";
import { ControleStoreModule } from '@/store/modules/ControleStore';
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { Component, Vue } from 'vue-property-decorator';
var TerminoCls = /** @class */ (function (_super) {
    __extends(TerminoCls, _super);
    function TerminoCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.clsApollo = new ClsApolloClient();
        _this.confirmouEnvioPorEmail = false;
        _this.enviarEmailResumo = false;
        _this.email = ControleStoreModule.dadosCliente.email;
        return _this;
    }
    Object.defineProperty(TerminoCls.prototype, "ControleStoreModule", {
        get: function () {
            return ControleStoreModule;
        },
        enumerable: false,
        configurable: true
    });
    TerminoCls.prototype.btFinalizar = function () {
        this.$router.push("/");
    };
    TerminoCls.prototype.btConfirmarEnvioEmail = function () {
        var _this = this;
        var enviarResultadoEmail = "\n      enviarResultadoEmail (idClienteQuiz: " + ControleStoreModule.idClienteQuiz + ", email: \"" + this.email + "\")\n      {\n        ok\n        mensagem\n      }\n    ";
        this.clsApollo.mutation(this, enviarResultadoEmail, 'enviarResultadoEmail', 'Enviando seu e-mail...').then(function (rs) {
            if (rs.ok) {
                _this.confirmouEnvioPorEmail = true;
            }
            else {
                MensagemStoreModule.exibirMensagem({ mensagem: 'Desculpe, não consegui enviar seu email!', titulo: 'Erro no servidor...', tipo: TipoMensagemType.erro });
            }
        }).catch(function () {
            MensagemStoreModule.exibirMensagem({ mensagem: 'Desculpe, não consegui enviar seu email!', titulo: 'Erro no servidor...', tipo: TipoMensagemType.erro });
        });
    };
    TerminoCls = __decorate([
        Component
    ], TerminoCls);
    return TerminoCls;
}(Vue));
export default TerminoCls;
