import { __decorate, __extends } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
var TesteCls = /** @class */ (function (_super) {
    __extends(TesteCls, _super);
    function TesteCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.supported = ('contacts' in navigator && 'ContactsManager' in window);
        _this.contatos = 'Carregando Contatos....';
        return _this;
    }
    TesteCls.prototype.getContatos = function () {
        var _this = this;
        //@ts-ignore
        navigator.contacts.getProperties().then(function (rsProps) {
            _this.contatos = rsProps;
            //@ts-ignore
            navigator.contacts.select(rsProps, { multiple: true }).then(function (rsContacts) {
                _this.contatos = rsContacts;
            }).catch(function (err) {
                _this.contatos = err.message;
            });
        });
    };
    TesteCls = __decorate([
        Component
    ], TesteCls);
    return TesteCls;
}(Vue));
export default TesteCls;
