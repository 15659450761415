import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { MensagemStoreModule, TipoMensagemType } from '@/store/modules/MensagemStore';
var MensagemCls = /** @class */ (function (_super) {
    __extends(MensagemCls, _super);
    function MensagemCls() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(MensagemCls.prototype, "MensagemStoreModule", {
        get: function () { return MensagemStoreModule; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MensagemCls.prototype, "TipoMensagemType", {
        get: function () { return TipoMensagemType; },
        enumerable: false,
        configurable: true
    });
    MensagemCls = __decorate([
        Component
    ], MensagemCls);
    return MensagemCls;
}(Vue));
export default MensagemCls;
