import { __decorate, __extends } from "tslib";
import { ControleStoreModule } from '@/store/modules/ControleStore';
import { Component, Vue, Watch } from 'vue-property-decorator';
export var TEXTO_SUBSTITUIR_RESPOSTA = '[[RESPOSTA]]';
export var SEM_RESPOSTA = "___________";
export var PATH_PUBLIC = "../../public/";
var QuestionarioCls = /** @class */ (function (_super) {
    __extends(QuestionarioCls, _super);
    function QuestionarioCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.resposta = '';
        return _this;
    }
    Object.defineProperty(QuestionarioCls.prototype, "ControleStoreModule", {
        get: function () {
            return ControleStoreModule;
        },
        enumerable: false,
        configurable: true
    });
    QuestionarioCls.prototype.tratarTextoPergunta = function (pergunta) {
        var substituir = this.resposta.length > 0 ? this.resposta : SEM_RESPOSTA;
        var retorno = '';
        if (pergunta && pergunta.length > 0) {
            retorno = pergunta.replace(TEXTO_SUBSTITUIR_RESPOSTA, substituir);
        }
        return retorno;
    };
    Object.defineProperty(QuestionarioCls.prototype, "perguntaAtual", {
        get: function () {
            return ControleStoreModule.perguntaAtual;
        },
        enumerable: false,
        configurable: true
    });
    QuestionarioCls.prototype.onChangePerguntaAtual = function (novo, old) {
        this.resposta = '';
    };
    Object.defineProperty(QuestionarioCls.prototype, "pergunta", {
        get: function () {
            return ControleStoreModule.perguntas[ControleStoreModule.perguntaAtual];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuestionarioCls.prototype, "textoAntesImagem", {
        get: function () {
            return this.tratarTextoPergunta(this.pergunta.textoAntesImagem);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuestionarioCls.prototype, "textoAposImagem", {
        get: function () {
            return this.tratarTextoPergunta(this.pergunta.textoAposImagem);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QuestionarioCls.prototype, "imagem", {
        get: function () {
            var retorno = { path: '', altura: '', largura: '' };
            if (this.pergunta && this.pergunta.imagemPath && this.pergunta.imagemPath.length > 0) {
                retorno = {
                    // path: require( '../assets/'.concat(this.pergunta.imagem.path) ),
                    path: require('../../public/img/'.concat(this.pergunta.imagemPath)),
                    largura: this.pergunta.imagemLargura ? this.pergunta.imagemLargura : '',
                    altura: this.pergunta.imagemAltura ? this.pergunta.imagemAltura : ''
                };
            }
            return retorno;
        },
        enumerable: false,
        configurable: true
    });
    // private clsApolloClient: ClsApolloClient = new ClsApolloClient()
    QuestionarioCls.prototype.setResposta = function (qualSelecao) {
        this.resposta = qualSelecao;
        ControleStoreModule.setResposta(qualSelecao);
    };
    __decorate([
        Watch('perguntaAtual')
    ], QuestionarioCls.prototype, "onChangePerguntaAtual", null);
    QuestionarioCls = __decorate([
        Component
    ], QuestionarioCls);
    return QuestionarioCls;
}(Vue));
export default QuestionarioCls;
