import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';
import { validadoresFrontEnd } from 'zlib-utils';
var QuizPerguntaOpcoesCls = /** @class */ (function (_super) {
    __extends(QuizPerguntaOpcoesCls, _super);
    function QuizPerguntaOpcoesCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.formValid = false;
        _this.dialog = false;
        _this.dialogDelete = false;
        _this.headers = [
            { text: 'Opção', value: 'opcao' },
            { text: 'Chave Totalizador', value: 'chaveTotalizador' },
            { text: 'Imagem Path', value: 'imagemPath' },
            { text: 'Imagem Altura', value: 'imagemAltura' },
            { text: 'Imagem Largura', value: 'imagemLargura' },
            { text: 'Ações', value: 'actions', sortable: false }
        ];
        _this.registros = [];
        _this.editedIndex = -1;
        _this.editedItem = _this.resetModel();
        _this.defaultItem = _this.resetModel();
        return _this;
    }
    Object.defineProperty(QuizPerguntaOpcoesCls.prototype, "validadoresFrontEnd", {
        get: function () { return validadoresFrontEnd; },
        enumerable: false,
        configurable: true
    });
    QuizPerguntaOpcoesCls.prototype.onChangeIdQuizPergunta = function () {
        Object.assign(this.registros, this.opcoes);
    };
    QuizPerguntaOpcoesCls.prototype.updateOpcoes = function () {
        this.$emit('updateOpcoes', this.registros);
    };
    QuizPerguntaOpcoesCls.prototype.resetModel = function () {
        return {
            opcao: '',
            chaveTotalizador: '',
            imagemAltura: '',
            imagemLargura: '',
            imagemPath: ''
        };
    };
    Object.defineProperty(QuizPerguntaOpcoesCls.prototype, "formTitle", {
        get: function () {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
        },
        enumerable: false,
        configurable: true
    });
    QuizPerguntaOpcoesCls.prototype.editItem = function (item) {
        this.editedIndex = this.registros.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
    };
    QuizPerguntaOpcoesCls.prototype.deleteItem = function (item) {
        this.editedIndex = this.registros.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
    };
    QuizPerguntaOpcoesCls.prototype.deleteItemConfirm = function () {
        this.registros.splice(this.editedIndex, 1);
        this.closeDelete();
        this.updateOpcoes();
    };
    QuizPerguntaOpcoesCls.prototype.close = function () {
        var _this = this;
        this.dialog = false;
        this.$nextTick(function () {
            _this.editedItem = Object.assign({}, _this.defaultItem);
            _this.editedIndex = -1;
        });
    };
    QuizPerguntaOpcoesCls.prototype.closeDelete = function () {
        var _this = this;
        this.dialogDelete = false;
        this.$nextTick(function () {
            _this.editedItem = Object.assign({}, _this.defaultItem);
            _this.editedIndex = -1;
        });
    };
    QuizPerguntaOpcoesCls.prototype.save = function () {
        //@ts-ignore
        if (this.$refs.form.validate()) {
            if (this.editedIndex > -1) {
                Object.assign(this.registros[this.editedIndex], this.editedItem);
            }
            else {
                this.registros.push(this.editedItem);
            }
            this.close();
            this.updateOpcoes();
        }
    };
    QuizPerguntaOpcoesCls.prototype.upItem = function (item, index) {
        var tmp = this.registros[index - 1];
        this.registros[index - 1] = item;
        this.registros[index] = tmp;
        this.updateOpcoes();
    };
    QuizPerguntaOpcoesCls.prototype.downItem = function (item, index) {
        var tmp = this.registros[index + 1];
        this.registros[index + 1] = item;
        this.registros[index] = tmp;
        this.updateOpcoes();
    };
    __decorate([
        Prop({ default: false })
    ], QuizPerguntaOpcoesCls.prototype, "opcoes", void 0);
    __decorate([
        Prop({ default: 0 })
    ], QuizPerguntaOpcoesCls.prototype, "idQuizPergunta", void 0);
    __decorate([
        Watch('idQuizPergunta')
    ], QuizPerguntaOpcoesCls.prototype, "onChangeIdQuizPergunta", null);
    QuizPerguntaOpcoesCls = __decorate([
        Component({
            directives: {
                mask: mask
            }
        })
    ], QuizPerguntaOpcoesCls);
    return QuizPerguntaOpcoesCls;
}(Vue));
export default QuizPerguntaOpcoesCls;
