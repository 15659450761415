import Vue from 'vue';
import Vuetify from 'vuetify/lib';
/**
 * Cores Fleek
 * Azul - C100 M40 Y0 K0 | #0099ff
 * Laranja - C0 M60 Y100 K0 | #ff6600
 */
import colors from 'vuetify/lib/util/colors';
Vue.use(Vuetify);
export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: colors.blue.darken2,
                secondary: '#FF6600',
                accent: colors.blue.accent1,
                error: colors.red.accent2,
                info: '#FF6600',
                success: colors.green,
                warning: colors.amber
            },
            dark: {
                primary: colors.blue.darken2,
                secondary: '#FF6600',
                accent: colors.blue.accent1,
                error: colors.red.accent2,
                info: '#FF6600',
                success: colors.green,
                warning: colors.amber
            },
        },
    },
});
/*

  theme: {
    themes: {
      dark: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },

  */
